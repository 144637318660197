import { AxiosResponse } from 'axios'
import API from '~/sdk/client'

interface PostTokenRequest {
  redirect_uri: string
  grant_type: string
  client_id: string|number
  code_verifier: string
  code: string
}

interface TokenResponse {
  access_token: string
  token_type: string
  expires_in: number
}

export async function post(payload: PostTokenRequest): Promise<AxiosResponse<TokenResponse>> {
  return await API.post('/internal/v1/token', payload)
}
