import { FunctionComponent, ReactElement, Fragment, useState, useEffect } from 'react'
import { AlertIcon, InformationIcon, RoundCheckIcon, CloseIcon } from 'components/Icons'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'

export type BannerType = 'info' | 'success' | 'warning' | 'message'
export type BannerPosition = 'top' | 'bottom'

export interface BannerProps extends React.PropsWithChildren {
  autoClose?: boolean, // Close banner after 4s
  onClose: () => void,
  showOnce?: boolean, // Only show once. Use new id to show it again
  position: BannerPosition,
  type: BannerType,
  open: boolean,
  id: string
}

const BannerTransition: FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      {children}
    </Transition.Child>
  )
}

export const Banner: FunctionComponent<BannerProps> = ({ children, type, position, id, open, onClose, autoClose, showOnce }) => {

  const [unique, setUnique] = useState('')
  const [shouldShow, setShouldShow] = useState(false)
  const cookieString = `${unique}=hide`

  useEffect(() => {
    setUnique(`${id}-${window.btoa(window.location.pathname)}`)
    setShouldShow(!document.cookie.includes(cookieString) || !showOnce)
  }, [])

  function getTypeIcon(type: BannerType): ReactElement | null {
    const iconClasses = 'w-6 h-6'
    switch (type) {
      case 'info':
        return <InformationIcon className={iconClasses} />
      case 'message':
        return <InformationIcon className={iconClasses} />
      case 'success':
        return <RoundCheckIcon className={iconClasses} />
      case 'warning':
        return <AlertIcon className={iconClasses} />
      default:
        return null
    }
  }

  function getTypeClasses(type: BannerType): string | null {
    switch (type) {
      case 'info':
        return 'bg-white text-black'
      case 'message':
        return 'bg-primary-600 text-white'
      case 'success':
        return 'bg-success-600 text-white'
      case 'warning':
        return 'bg-warning-700 text-white'
      default:
        return null
    }
  }

  function getPositionClasses(position: BannerPosition): string | null {
    switch (position) {
      case 'top':
        return 'top-12'
      case 'bottom':
        return 'bottom-12'
      default:
        return null
    }
  }

  const wrapperClassList = classNames(
    'fixed left-0 z-10 w-full px-4 flex justify-center',
    getPositionClasses(position)
  )

  const classList = classNames(
    'flex justify-between p-4 gap-2 rounded-small shadow-medium',
    getTypeClasses(type)
  )

  function hideBanner() {
    document.cookie = cookieString
    onClose()
  }

  useEffect(() => {
    if (autoClose) {
      setTimeout(() => {
        hideBanner()
      }, 4000)
    }
  }, [autoClose, open])

  return (
    <>
      <Transition.Root show={open && shouldShow} as={Fragment}>
        <div className={wrapperClassList}>
          <BannerTransition>
            <div className="w-full max-w-7xl">
              <div className={classList}>
                  <div className="flex flex-row">
                    { getTypeIcon(type) }
                  </div>
                  <div className="flex-grow">
                    {children}
                  </div>
                  <div className="justify-self-end cursor-pointer" onClick={() => { hideBanner() }}>
                    <CloseIcon className="w-6"/>
                  </div>
              </div>
            </div>
          </BannerTransition>
        </div>
      </Transition.Root>
    </>
  )
}

