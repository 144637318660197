const animateCSS = (node: HTMLElement | null, animation: string, prefix: string = 'animate__'): Promise<string> =>
  new Promise((resolve, reject) => {

    // ensure an element
    if (node === null) throw reject('No node to animate');

    const animationName = `${prefix}${animation}`;

    node.classList.add(`${prefix}animated`, animationName);

    const handleAnimationEnd = (event: Event) => {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve('Animation ended');
    };

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
  });

export default animateCSS;
