import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export interface Entity {
  account: string
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/settings/autogiro')
}

export async function patch(payload: BaseExchange<Entity>): Promise<BaseResponse<Entity>> {
  return await API.patch('internal/v1/company/settings/autogiro', payload)
}
