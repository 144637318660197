import React, { useState, useEffect, forwardRef, Ref, createRef } from 'react';
import { masker } from '@monto/react-common-ui';

import { Input, InputProps } from '../Input/Input';

interface AmountProps extends Omit<InputProps, 'onChange'> {
  currency?: 'SEK' | 'NOK' | 'EUR';
  locale?: 'sv' | 'en' | 'no';
  otherRef?: Ref<HTMLInputElement>;
  value: string | number,
  onChange?: (value: number | string) => void;
}

// common currency mask, but without the currency
export const CurrencyMask = (locale: string) => masker(
  (value) => Number(value).toLocaleString(locale),
  (value) => String(value).replace(/\D/g, ''),
  (value) => Number(String(value).replace(/\D/g, ''))
);

const AmountElement: React.FC<AmountProps> = ({
  currency = 'SEK',
  locale = 'sv',
  onChange,
  otherRef,
  value,
  ...props
}) => {

  const [inputValue, setInputValue] = useState<string | number>(CurrencyMask(locale).mask(value));
  const inputRef = otherRef || createRef();

  useEffect(() => {
    setInputValue(CurrencyMask(locale).mask(value));
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = CurrencyMask(locale).unmask(e.target.value);
    setInputValue(e.target.value);
    onChange && onChange(newValue);
  };

  return (
    <Input
      {...props}
      value={inputValue}
      prepend={currency}
      onChange={handleChange}
      ref={inputRef}
    />
  );
}

const Amount = forwardRef<HTMLInputElement, AmountProps>(function Amount(props, ref) {
  return (
    <AmountElement otherRef={ref} {...props} />
  );
});

export default Amount;
