import { HasClassName } from '@monto/react-common-ui'
import { FunctionComponent } from 'react'

export const InformationIcon: FunctionComponent<HasClassName> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 9h-2V7h2v2Zm0 8h-2v-6h2v6ZM12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z" fill="currentColor"/>
    </svg>
  )
}

