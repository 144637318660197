import { BaseResponse, Money } from '~/sdk/shared'
import { HasRequirements } from './productTypes'
import API from '~/sdk/client'

export interface Entity extends HasRequirements {
  enabled: boolean
  availableAmount: Money
  interest: number
  originalInterest: number
  owedAmount: Money
  pendingAmount: Money
  unsecuredAmount: Money
  feePerInvoice: Money
  totalFees: Money
  securityMethod: 'CUSTOMERS' | 'INVOICES' | null
  minimumRepaymentAmount: Money
  hasActiveFactoringApplication: boolean
  nextPayment: Money
  accruedInterest: Money
  securedAmount: Money
  fullRepaymentAmount: Money
  accruedOverdueInterest: Money
  accruedFee: Money
  formattedInterest: string
  formattedOriginalInterest: string
  termsUrl: string | null
  preliminaryAvailableAmount: Money | null
  isActive: boolean
  eligibleForFinancing: boolean
}

export async function get(): Promise<BaseResponse<Entity>> {
  return API.get('internal/v2/company/products/invoice-discounting')
}
