import { useEffect, useRef } from 'react';

type ModalOptions = {
  closeOnClickOutside?: boolean,
  isModal?: boolean,
  isDraggable?: boolean,
}

export default function useModal(modalOptions?: ModalOptions) {

  // ensure defaults
  const options = {
    ...{
      closeOnClickOutside: false,
      isModal: false,
      size: 'auto'
    }, ...modalOptions
  };

  const ref = useRef<HTMLDialogElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') onClose();
  };

  const onOpen = () => {
    if (options.isModal) ref.current?.showModal();
    else {
      ref.current?.show();
      document.addEventListener('keydown', handleKeyDown);
    }
  };

  const onClose = () => {
    ref.current?.close();
    document.removeEventListener('keydown', handleKeyDown);
  };

  useEffect(() => {
    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return {
    ref, onOpen, onClose
  };
}
