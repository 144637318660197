import { createSlice, createAsyncThunk, PayloadAction, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { DocumentType, get as getDocuments } from 'sdk/internal/v1/company/documents';
import { Document as DocumentEntity, destroy } from 'sdk/internal/v1/company/documents';
import { RootState } from '~/store';
import Axios from 'axios';
import API from '~/sdk/client';
import { resetAll } from '../actions';
import AppError from '~/utils/AppError';
import { date } from '~/utils/date';

const documentsAdapter = createEntityAdapter<DocumentEntity>({
  selectId: (document) => document.id,
});

export const documentsSelectors = documentsAdapter.getSelectors(
  (state: RootState) => state.documents
);

export interface DocumentsState {
  entities: Record<string, DocumentEntity | undefined>;
  ids: (string | number)[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  timestamp: number | undefined;
  error: string | undefined;
  loading: boolean;
  uploadStatus: 'idle' | 'uploading' | 'success' | 'error';
  hasUploadedDocuments: boolean;
}

const initialState: DocumentsState = {
  ...documentsAdapter.getInitialState(),
  status: 'idle',
  error: undefined,
  loading: false,
  timestamp: undefined,
  uploadStatus: 'idle',
  hasUploadedDocuments: false
};

interface GetDocumentParams {
  page?: number;
  size?: number;
}

export const selectDocumentById = createSelector([documentsSelectors.selectById], (document) => document);
export const selectAllDocuments = createSelector([documentsSelectors.selectAll], (document) => document);

export const showDocumentsByType = createSelector(
  [documentsSelectors.selectAll, (state: RootState, type: string) => type],
  (documents, type) => {
    return documents.filter(document => document.type === type);
  }
);

export const fetchDocuments = createAsyncThunk(
  'documents/getDocuments',
  async ({ page = 1, size = 25 }: GetDocumentParams = {}) => {

    try {
      const response = await getDocuments({ page, size });
      return response.data.data;
    } catch (error) {
      throw new AppError(error, {
        where: 'documentSlice',
        action: 'fetchDocuments'
      });
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (id: number) => {
    try {
      await destroy({id})
      return id;
    } catch (error) {
      throw new AppError(error, {
        where: 'documentSlice',
        action: 'delete document',
        documentId: id,
      });
    }
  }
);

export const uploadDocument = createAsyncThunk(
  'documents/upload',
  async ({file, type}: {file: File, type: DocumentType}, { dispatch, rejectWithValue }) => {

    const lastDot = file.name.lastIndexOf('.');
    const extension = file.name.substring(lastDot + 1);

    try {
      const { data: signedUrlResponse } = await API.post(
        'internal/v1/company/documents/upload',
        { data: { extension, type } }
      );

      await Axios.put(signedUrlResponse.data.url, file, {
        headers: { 'Content-Type': file.type }
      });

      await API.post('internal/v1/company/documents', {
        data: {
          source: signedUrlResponse.data.source,
          filename: file.name,
          type: type
        }
      });

      dispatch(fetchDocuments({}));

    } catch (error) {

      // log to sentry
      return rejectWithValue(new AppError(error, {
        where: 'documentSlice',
        action: 'upload document',
        documentType: type,
      }));
    }
  }
);

const hasUploadedDocuments = (state: DocumentsState) => {
  return Object.values(state.entities).some((document) => {
    return (document?.type === DocumentType.AccountingDocBsPl && date(document.createdAt).isLessThanXDaysOld(30));
  });
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    resetDocumentsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchDocuments.fulfilled, (state, action: PayloadAction<DocumentEntity[]>) => {
        state.loading = false;

        // update state.
        documentsAdapter.setAll(state, action.payload);

        // update timestamp
        state.timestamp = Date.now().valueOf();

        // did user upload a accounting doc?
        state.hasUploadedDocuments = hasUploadedDocuments(state);
      })
      .addCase(fetchDocuments.rejected, (state) => {
        state.loading = false;
        state.error = 'Could not fetch documents';
      })
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        documentsAdapter.removeOne(state, action);
        state.hasUploadedDocuments = hasUploadedDocuments(state);
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(uploadDocument.pending, (state) => {
        state.uploadStatus = 'uploading';
      })
      .addCase(uploadDocument.fulfilled, (state) => {
        state.uploadStatus = 'success';
      })
      .addCase(uploadDocument.rejected, (state) => {
        state.uploadStatus = 'error';
      });
  }
});

export const {
  resetDocumentsState
} = documentsSlice.actions;

export default documentsSlice.reducer;
