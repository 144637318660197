import { CriteriaMode, FieldValues, useForm, UseFormReturn, ValidationMode, DefaultValues } from 'react-hook-form'
import { Textarea } from '~/components/Form/Textarea/Textarea'
import { Checkbox } from '~/components/Form/Checkbox/Checkbox'
import { Select } from '~/components/Form/Select/Select'
import { Group } from '~/components/Form/Group/Group'
import { Input } from '~/components/Form/Input/Input'
import { Radio } from '~/components/Form/Radio/Radio'
import { Autocomplete } from '~/components/Form/Autocomplete/Autocomplete'
import { yupResolver } from '@hookform/resolvers/yup'
import { FileUploadButton } from '~/components/Form/FileUploadButton/FileUploadButton'
import { Switch } from '~/components/Form/_Switch/Switch'
import { Phone } from '~/components/Form/Phone/Phone'
import Amount from '~/components/Form/Amount/Amount'
import { AnyObjectSchema } from 'yup'

export interface FormProps<T extends FieldValues> {
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit' | undefined
  onSubmit: (data: T) => Promise<void>
  // eslint-disable-next-line @typescript-eslint/ban-types
  children(props: UseFormReturn<T, object>): JSX.Element
  mode?: keyof ValidationMode | undefined
  shouldUseNativeValidation?: boolean
  defaultValues: DefaultValues<T>
  criteriaMode?: CriteriaMode
  shouldFocusError?: boolean
  shouldUnregister?: boolean
  schema: AnyObjectSchema
  delayError?: undefined
  context?: undefined
  id?: string
}

const FormElement = <T extends FieldValues>(props: FormProps<T>): JSX.Element => {
  const {
    schema,
    mode = 'onBlur',
    reValidateMode = 'onChange',
    onSubmit,
    defaultValues,
    context,
    criteriaMode = 'firstError',
    shouldFocusError = true,
    shouldUnregister = false,
    shouldUseNativeValidation = false,
    delayError,
    children,
    id
  } = props

  const form = useForm<T>({
    resolver: yupResolver(schema),
    shouldUseNativeValidation,
    shouldFocusError,
    shouldUnregister,
    reValidateMode,
    defaultValues,
    criteriaMode,
    delayError,
    context,
    mode,
  })

  return (
    <form id={id} onSubmit={form.handleSubmit(onSubmit)}>
      {children(form)}
    </form>
  )
}

export const Form = Object.assign(FormElement, {
  Autocomplete,
  Textarea,
  Checkbox,
  Select,
  Input,
  Group,
  Radio,
  FileUploadButton,
  Switch,
  Phone,
  Amount
})
