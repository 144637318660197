import { configureStore } from '@reduxjs/toolkit';
import providersReducer from './providers/providersSlice';
import guarantorsReducer from './guarantors/guarantorsSlice';
import userReducer from './user/userSlice';
import applicationSubmitReducer from './applicationSubmit/applicationSubmitSlice';
import documentsReducer from './documents/documentsSlice';
import applicationReducer from './application/applicationSlice';
import invoicesReducer from './invoices/invoicesSlice';
import toasterReducer from './toaster/toasterSlice';
import smoothReducer from './smoothApp/smoothApp';

import { invoicesApi } from '~/services/invoices.service';
import { customersApi } from '~/services/customers.service';
import { userApi } from '~/services/user.service';
import { productApi } from '~/services/product.service';
import { settingsApi } from '~/services/settings.service';
import { companyUsersApi } from '~/services/company.users.service';

import { companyBoardMembersApi } from '~/services/company.board-members.service';
import { guarantorsApi } from '~/services/guarantors.service';
import { applicationsApi } from '~/services/applications.service';

const store = configureStore({
  reducer: {
    providers: providersReducer,
    guarantors: guarantorsReducer,
    user: userReducer,
    applicationsubmit: applicationSubmitReducer,
    documents: documentsReducer,
    application: applicationReducer,
    invoices: invoicesReducer,
    toaster: toasterReducer,
    smoothApp: smoothReducer,
    [invoicesApi.reducerPath]: invoicesApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [companyUsersApi.reducerPath]: companyUsersApi.reducer,
    [companyBoardMembersApi.reducerPath]: companyBoardMembersApi.reducer,
    [guarantorsApi.reducerPath]: guarantorsApi.reducer,
    [applicationsApi.reducerPath]: applicationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([
      invoicesApi.middleware,
      userApi.middleware,
      customersApi.middleware,
      productApi.middleware,
      settingsApi.middleware,
      companyUsersApi.middleware,
      companyBoardMembersApi.middleware,
      guarantorsApi.middleware,
      applicationsApi.middleware
    ])
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
