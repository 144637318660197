import { AlertOulineIcon, CheckIcon, CloseIcon, InformationIcon } from '@monto/react-common-ui';
import { useEffect, useRef, useState } from 'react';
import useToaster from '~/hooks/useToaster';
import ReactDOM from 'react-dom';
import animateCSS from '~/utils/animateCSS';
import { ErrorIcon } from '@monto/ui';

const Toaster = ({ appendToID }: { appendToID?: string }) => {

  const toaster = useToaster();
  const [appendTarget, setAppendTarget] = useState<Element | null>(null);
  const toasterContainerRef = useRef<HTMLDivElement>(null);

  // will store timeout id at each toaster id
  const [timeouts, setTimeouts] = useState<Record<string, NodeJS.Timeout>>({});

  useEffect(() => {
    const target = appendToID ? document.getElementById(appendToID) : document.body;
    setAppendTarget(target);
  }, [appendToID]);

  const handleRemove = async (id: string) => {
    const elem = document.getElementById(id);
    await animateCSS(elem, 'backOutDown');
    toaster.remove(id);
  };

  const handleTimeout = (id: string) => {

    // remove or add timeout for given toast
    if (timeouts[id]) clearTimeout(timeouts[id]);
    else handleRemove(id);

    // update the timeouts hash table
    const newTimeouts = { ...timeouts };
    delete newTimeouts[id];
    setTimeouts(newTimeouts);
  };

  useEffect(() => {

    const toasts = Object.entries(toaster.state.toasts);

    // add timeouts for newly added toasts
    toasts.forEach(([id, toast]) => {
      if (toast.durationInSeconds && !timeouts[id]) {
        const timeoutId = setTimeout(() => handleTimeout(id), toast.durationInSeconds * 1000);
        setTimeouts(prev => ({ ...prev, [id]: timeoutId }));
      }
    });

    // make sure each toast nicely stacks on top of its older sibbling
    if (!toasterContainerRef.current) return;
    const toasterNodes = toasterContainerRef.current.children;
    let cumulativeHeight = 0;

    async function showToast(node: Element, index: number) {
      const htmlNode = node as HTMLElement;
      htmlNode.style.bottom = `${cumulativeHeight}px`;
      cumulativeHeight += htmlNode.offsetHeight + 10;

      if (toasts.length === 1) {
        htmlNode.classList.add('animate__faster');
        await animateCSS(htmlNode, 'slideInUp');
      }
    }

    Array.from(toasterNodes).forEach(showToast);

  }, [toaster.state.toasts]);

  // while user is hovering the toaster, we don't want it
  // to disappear, so we will remove the time out for this toaster
  const handleMouseEnter = (id: string) => {
    handleTimeout(id);
  };

  const handleMouseLeave = (id: string) => {

    const toast = toaster.state.toasts[id];

    if (toast && toast.durationInSeconds) {

      // set a new time out
      const timeoutId = setTimeout(() => handleTimeout(id), (toast.durationInSeconds / 2) * 1000);
      setTimeouts(prev => ({ ...prev, [id]: timeoutId }));
    }
  };

  // If appendTarget is not yet set, don't render the toaster
  if (!appendTarget) return null;

  return ReactDOM.createPortal(
    <div data-component="toaster" ref={toasterContainerRef}>
      {Object.entries(toaster.state.toasts).map(([id, toast]) => (
        <div
          className={`toast ${toast.type}`}
          key={id}
          id={id}
          onMouseEnter={() => handleMouseEnter(id)}
          onMouseLeave={() => handleMouseLeave(id)}
        >
          <div className="toaster-type-icon">
            {toast.type === 'info' && <InformationIcon />}
            {toast.type === 'warning' && <AlertOulineIcon />}
            {toast.type === 'success' && <CheckIcon />}
            {toast.type === 'error' && <ErrorIcon />}
          </div>
          <div className="toaster-inner-wrapper">
            <header>
              <h5>{toast.title}</h5>
              <button className="close" onClick={() => toaster.remove(id)}>
                <CloseIcon />
              </button>
            </header>
            <div className="toaster-message">{toast.message}</div>
            <footer>
              {toast.buttons && toast.buttons.map((button, index) => (
                <button key={index} className={`button toaster-${button.type}`} onClick={() => {
                  if (button.cta) button.cta();
                  toaster.remove(id);
                }}>
                  {button.label}
                </button>
              ))}
            </footer>
          </div>
        </div>
      ))}
    </div>,
    appendTarget || document.body
  );
};

export default Toaster;
