import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SectionsType =
  'loan-terms'
  | 'autogiro'
  | 'credit-info'
  | 'your-company'
  | 'guarantors'
  | 'signature'
  | 'payout-account'
  | 'bank-details'
  | 'contact-info';

type SmoothMeta = {
  isComplete: boolean,
  isVisible: boolean,
  isError: boolean,
  isSubOf: SectionsType | undefined,
}

type SectionsMeta = Record<SectionsType, SmoothMeta>;

type InitialStateType = {
  sections: SectionsMeta,
  canSubmit: boolean,
  highlighted: SectionsType | undefined,
  showErrors: boolean,
  current: SectionsType | undefined,
  firstIncompleteSection: SectionsType | undefined
}

type SetSectionMetaPayload = {
  sectionType: SectionsType,
  metaType: keyof SmoothMeta,
  value: boolean | SectionsType | undefined
}

const createInitialState = (): SectionsMeta => {

  const defaultSmoothMeta: SmoothMeta = {
    isComplete: false,
    isVisible: true,
    isError: false,
    isSubOf: undefined,
  };

  const sections: SectionsMeta = {
    'loan-terms': { ...defaultSmoothMeta },
    'your-company': { ...defaultSmoothMeta },
    'contact-info': { ...defaultSmoothMeta, ...{ isSubOf: 'your-company' } },
    'credit-info': { ...defaultSmoothMeta, ...{ isSubOf: 'your-company' } },
    'guarantors': { ...defaultSmoothMeta },
    'bank-details': { ...defaultSmoothMeta },
    'autogiro': { ...defaultSmoothMeta, ...{ isSubOf: 'bank-details' } },
    'payout-account': { ...defaultSmoothMeta, ...{ isSubOf: 'bank-details' } },
    'signature': { ...defaultSmoothMeta },
  };

  return sections;
};

const initialState: InitialStateType = {
  sections: createInitialState(),
  canSubmit: false,
  firstIncompleteSection: undefined,
  showErrors: false,
  highlighted: undefined,
  current: undefined
};

const smoothAppSlice = createSlice({
  name: 'smoothApp',
  initialState,
  reducers: {
    resetSmoothAppState: () => initialState,
    updateSectionMeta: (state, action: PayloadAction<SetSectionMetaPayload>) => {

      const { sectionType, value, metaType } = action.payload;

      if (metaType === 'isSubOf' && (typeof value === 'string' || typeof value === 'undefined')) {
        state.sections[sectionType][metaType] = value;
      } else if (metaType !== 'isSubOf' && typeof value === 'boolean') {
        state.sections[sectionType][metaType] = value;
      }

      // set invisible sections to complete
      Object.entries(state.sections).forEach(([key, section]) => {
        if (!section.isVisible) section.isComplete = true;
      });

      // set canSubmit state
      state.canSubmit = Object.values(state.sections).every(key => key.isComplete === true);

      // set firstIncompleteSection state
      const firstIncomplete = Object.entries(state.sections).find(([key, value]) => !value.isComplete);
      const section = firstIncomplete ? firstIncomplete[0] as SectionsType : undefined;

      // if it is a sub section, mark main section as incomplete
      if (section && state.sections[section].isSubOf) state.firstIncompleteSection = state.sections[section].isSubOf;
      else state.firstIncompleteSection = section;
    },
    setHighlighted: (state, action: PayloadAction<SectionsType>) => {
      state.highlighted = action.payload;
    },
    setCurrent: (state, action: PayloadAction<SectionsType | undefined>) => {
      state.current = action.payload;
    },
    showErrors: (state, action: PayloadAction<boolean>) => {
      state.showErrors = action.payload;
    }
  },
});

export const { resetSmoothAppState, updateSectionMeta, setHighlighted, setCurrent, showErrors } = smoothAppSlice.actions;
export default smoothAppSlice.reducer;
