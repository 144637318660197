import React, { createContext, useEffect, useState } from 'react'
import { deleteCookie, getCookie, setCookie } from '~/utils/cookies';
import { useMe } from '~/hooks/useMe';

export const LS_ORIGINAL_AUTH_KEY = '_auth.original_token'
export const LS_IMPERSONATE_KEY = '_auth.impersonate'
export const LS_AUTH_KEY = '_auth.token'

export type AuthTokenContract = {
  accessToken: string
  tokenType: string
  expiresAt: string
}

export type AuthContextContract = {
  setImpersonation: (token: AuthTokenContract) => void
  setToken: (token: AuthTokenContract) => void
  exitImpersonating: () => void
  authenticate: () => void
  impersonating: boolean
  logout: () => void
}

export const AuthContext = createContext<AuthContextContract>({
  exitImpersonating: () => { },
  setImpersonation: () => { },
  authenticate: async () => { },
  impersonating: false,
  setToken: () => { },
  logout: () => { },
});

export const AuthProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [impersonating, setImpersonating] = useState<boolean>(false);
  const user = useMe();


  useEffect(() => {
    if (hasToken() && !user.data) {
      setImpersonating(isImpersonating())
      authenticate();
    } else {
      user.refetch();
    }

  }, []);

  /**
   * Method to sign the currently authenticated user out.
   */
  function logout() {
    deleteCookie(LS_IMPERSONATE_KEY);
    deleteCookie(LS_AUTH_KEY);
    deleteCookie(LS_AUTH_KEY);
    sessionStorage.removeItem('piwik_new_user_created');
    sessionStorage.removeItem('piwik_email_verification_complete');
    sessionStorage.removeItem('piwik_application_started');
    user.reset();
  }

  /**
   * Method to store the token
   */
  function setToken(token: AuthTokenContract | null) {
    setCookie(LS_AUTH_KEY, JSON.stringify(token));
  }

  /**
   * Method to store the token as impersonator
   */
  function setImpersonation(token: AuthTokenContract | null) {

    const originalToken = getCookie(LS_AUTH_KEY)

    if (!originalToken) {
      throw new Error('Could not impersonate, since there is no previous token')
    }

    setCookie(LS_ORIGINAL_AUTH_KEY, originalToken);
    setCookie(LS_IMPERSONATE_KEY, 'true');

    setImpersonating(true);
    setToken(token);
  }

  /**
   * Determinates if we have a stored token or not
   */
  function hasToken() {
    return !!getCookie(LS_AUTH_KEY);
  }

  /**
   * Determinates if we're in impersonate mode or not
   */
  function isImpersonating(): boolean {
    return !!(getCookie(LS_IMPERSONATE_KEY) && getCookie(LS_IMPERSONATE_KEY) === 'true');
  }

  function exitImpersonating() {
    const originalToken = getCookie(LS_ORIGINAL_AUTH_KEY)

    if (!originalToken) {
      throw new Error('Could not exit impersonation, since there is no original token')
    }

    setCookie(LS_AUTH_KEY, originalToken);
    deleteCookie(LS_ORIGINAL_AUTH_KEY);
    deleteCookie(LS_IMPERSONATE_KEY);

    setImpersonating(false);
  }

  /**
   * Method to authenticate
   */
  async function authenticate() {

    try {
      await user.refetch();
    } catch (e) {
      user.reset();
    }
  }

  return (
    <AuthContext.Provider value={{ authenticate, setToken, setImpersonation, exitImpersonating, impersonating, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
