import classNames from 'classnames'
import { HasClassName } from '@monto/react-common-ui'
import { forwardRef, FunctionComponent, InputHTMLAttributes, Ref, useState } from 'react'

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement>, HasClassName {
  innerRef?: Ref<HTMLInputElement>
}

function getStateStyling ({ disabled = false, readOnly = false, checked = false }) {
  if (disabled) {
    return { base: 'bg-gray-200 border-gray-400 cursor-not-allowed', handle: 'bg-gray-100' }
  }

  if (readOnly) {
    return { base: 'bg-gray-700 border-gray-400 cursor-not-allowed', handle: 'bg-white' }
  }

  if (checked) {
    return { base: 'bg-primary-600 border-primary-600', handle: 'bg-white' }
  }

  return { base: 'bg-gray-300 border-gray-400', handle: 'bg-gray-50' }
}

const SwitchElement: FunctionComponent<SwitchProps> = ({ className, innerRef, onChange, onFocus, onBlur, ...rest }) => {
  const [focus, setFocus] = useState(false)
  const { base: switchStateStyles, handle: handleStateStyles } = getStateStyling(rest)

  const switchStyles = classNames(
    switchStateStyles,
    focus ? 'ring-4 ring-primary-50' : '',
    'relative inline-flex p-px border h-6 w-10 items-center rounded-large'
    );

  const handleStyles = classNames(
    handleStateStyles,
    rest.checked ? 'translate-x-4' : 'translate-x-0',
    'inline-block h-5 w-5 transform rounded-large bg-white transition'
  );

  function onChangeHandler (event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event)
    }
  }

  function onFocusHandler (event: React.FocusEvent<HTMLInputElement>) {
    setFocus(true)

    if (onFocus) {
      onFocus(event)
    }
  }

  function onBlurHandler (event: React.FocusEvent<HTMLInputElement>) {
    setFocus(false)

    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <div
      className={classNames(switchStyles, className)}
    >
      <input
        type="checkbox"
        onChange={onChangeHandler}
        className="sr-only"
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
        ref={innerRef}
        {...rest}
      />
      <span className={handleStyles} />
    </div>
  )
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(function Switch(props, ref) {
  return (
    <SwitchElement {...props} innerRef={ref} />
  )
})
