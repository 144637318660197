import API from '~/sdk/client'
import { BaseExchange } from '~/sdk/shared'

export interface SignUpData {
  amount?: number
  socialSecurityNumber?: string
  phone?: string
  email?: string
  validateUnique?: boolean
}

export async function post(payload: BaseExchange<SignUpData>): Promise<SignUpData> {
  return await API.post('internal/v1/onboarding/leads', payload)
}