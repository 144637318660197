import { EmptyBaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

type Query = {
  type: 'QUICK' | 'NORMAL' | 'FULL',
  assess: boolean,
}

export async function sync({ type, assess }: Query): Promise<EmptyBaseResponse> {
  return await API.post('internal/v1/company/jobs/sync', { data: { type, assess }})
}
