import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

interface AutoLoginRequest {
  location: string
}

interface AutoLoginResponse {
  url: string
}

export async function post(payload: BaseExchange<AutoLoginRequest>): Promise<BaseResponse<AutoLoginResponse>> {
  return await API.post<BaseExchange<AutoLoginRequest>, BaseResponse<AutoLoginResponse>>('internal/v1/user/autologin', payload)
}
