import { useForm } from 'react-hook-form';
import { Form } from '~/components/Form/Form';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import useModal from '~/hooks/useModal';
import * as yup from 'yup';
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { sendFeedback } from '@sentry-internal/feedback';
import { InformationIcon } from '../Icons';
import { useMe } from '~/hooks/useMe';
import useAppState from '~/hooks/useApp';
import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useToaster from '~/hooks/useToaster';

type FeedbackFormData = {
  name?: string,
  email?: string,
  message: string,
  feedbackType: 'question' | 'bug-report'
}

const feedbackFormSchema = (): Yup.ObjectSchema<any> => {
  return yup.object().shape({
    name: yup.string()
      .trim()
      .notRequired(),
    email: yup.string()
      .trim()
      .email('Invalid email format')
      .notRequired(),
    message: yup.string()
      .trim()
      .required('Message is required')
  });
};

export const SentryFeedback = () => {

  const { t } = useTranslation();

  const { formState, register, handleSubmit, reset } = useForm<FeedbackFormData>({
    resolver: yupResolver(feedbackFormSchema()),
    mode: 'onSubmit',
    defaultValues: {
      message: undefined,
      feedbackType: 'question'
    }
  });

  const user = useMe();
  const appState = useAppState();
  const toaster = useToaster();
  const modal = useModal({ isModal: true });

  // show/hide the modal.
  useEffect(() => {
    if (appState.state.showFeedbackDialog === true) modal.onOpen();
    else modal.onClose();
  }, [appState.state.showFeedbackDialog]);

  const submitHandler = async (data: FeedbackFormData) => {
    try {
      if (data.message) {
        sendFeedback({
          message: data.message,
          email: user.data?.email,
          name: `${user.data?.firstName} ${user.data?.lastName}`,
        }, {
          includeReplay: true,
        });

        reset();
        modal.onClose();

        toaster.show({
          type: 'success',
          title: t('toasts:feedback-submit-success.title'),
          message: t('toasts:feedback-submit-success.message'),
          buttons: [toaster.commonButtons('ok')]
        });
      }

    } catch (e) {
      toaster.show({
        type: 'error',
        title: t('toasts:feedback-submit-error.title'),
        message: t('toasts:feedback-submit-error.message'),
        buttons: [
          toaster.commonButtons('try-again', () => submitHandler(data)),
          toaster.commonButtons('contact-us')
        ]
      });
    }
  };

  const closeDialogListener = () => {
    appState.feedbackDialogIsVisible(false);
  };

  // update store when dialog closes
  modal.ref.current?.addEventListener('close', closeDialogListener);

  useEffect(() => {
    return () => {
      modal.ref.current?.removeEventListener('close', closeDialogListener);
    };
  }, []);

  return (
    <SimpleDialog
      onClose={modal.onClose}
      ref={modal.ref}
      labelledBy="sentry-feedback-button"
      size="medium"
      appendToID="app-content"
    >
      <div className="sentry-feedback">
        <header>
          <InformationIcon />
          <div>
            <h1>{t('feedback-dialog:title')}</h1>
            <p>
              {t('feedback-dialog:intro')}
            </p>
          </div>
        </header>
        <main>
          <form className="form sentry-feedback-form" method="dialog" onSubmit={handleSubmit(submitHandler)}>
            <>
              {/* <label>
                <input type="radio" value="question" {...register('feedbackType')} />
                {t('feedback-dialog:question')}
              </label>
              <label>
                <input type="radio" value="bug-report" {...register('feedbackType')} />
                {t('feedback-dialog:bug-report')}
              </label> */}

              <Form.Group label={'Your message'} htmlFor={'message'} formState={formState}>
                {(state) => (
                  <Form.Textarea
                    {...register('message')}
                    placeholder={t('feedback-dialog:placeholder')}
                    state={state}
                  />
                )}
              </Form.Group>
            </>
            <footer>
              <button className="button cancel" onClick={() => {
                reset();
                modal.onClose();
              }}>
                {t('feedback-dialog:cancel')}
              </button>
              <button type="submit" className="button primary">
                {t('feedback-dialog:cta')}
              </button>
            </footer>
          </form>
        </main>
      </div>
    </SimpleDialog>
  );
};
