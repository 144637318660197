import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseExchange } from '~/sdk/shared';
import baseQuery from './base-query';
import { Entity as AutogiroEntity } from 'sdk/internal/v1/company/settings/autogiro';
import { Entity as BankAccountEntity } from 'sdk/internal/v1/company/settings/bank-account';
import { Entity as BookkeepingEntity } from 'sdk/internal/v1/company/settings/bookkeeping';

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery,
  tagTypes: ['AutoGiro', 'BankAccount', 'Bookkeeping'],
  endpoints: (builder) => ({
    getAutogiro: builder.query<BaseExchange<AutogiroEntity>, void>({
      query: () => ({
        url: '/internal/v1/company/settings/autogiro',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<AutogiroEntity> => {
        return { data: response?.data } as BaseExchange<AutogiroEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'AutoGiro' }] : [];
      },
    }),
    patchAutogiro: builder.mutation<BaseExchange<AutogiroEntity>, string>({
      query: (accountString) => ({
        url: '/internal/v1/company/settings/autogiro',
        method: 'PATCH',
        body: {
          data: {
            account: accountString
          }
        }
      }),
      invalidatesTags: [{ type: 'AutoGiro' }]
    }),
    getBankAccount: builder.query<BaseExchange<BankAccountEntity>, void>({
      query: () => ({
        url: '/internal/v1/company/settings/bank-account',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<BankAccountEntity> => {
        return { data: response?.data } as BaseExchange<BankAccountEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'BankAccount' }] : [];
      },
    }),
    patchBankAccount: builder.mutation<BaseExchange<BankAccountEntity>, BaseExchange<BankAccountEntity>>({
      query: (accountData) => {
        return ({
          url: '/internal/v1/company/settings/bank-account',
          method: 'PATCH',
          body: accountData,
        })
      },
      invalidatesTags: [{ type: 'BankAccount' }]
    }),
    getBookkeeping: builder.query<BaseExchange<BookkeepingEntity>, void>({
      query: () => ({
        url: '/internal/v1/company/settings/bookkeeping',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<BookkeepingEntity> => {
        return { data: response?.data } as BaseExchange<BookkeepingEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'Bookkeeping' }] : [];
      },
    }),
    patchBookkeeping: builder.mutation<BaseExchange<BookkeepingEntity>, { data: BookkeepingEntity }>({
      query: (payload) => ({
        url: '/internal/v1/company/settings/bookkeeping',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Bookkeeping' }]
    }),
  }),
});

export const {
  useGetAutogiroQuery,
  usePatchAutogiroMutation,
  useGetBankAccountQuery,
  usePatchBankAccountMutation,
  useGetBookkeepingQuery,
  usePatchBookkeepingMutation
} = settingsApi;
