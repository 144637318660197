import useTranslation from 'next-translate/useTranslation'

interface ReturnType {
  config: Record<string, unknown>
}

interface YupValidationError {
  value: any,
  originalValue: any,
  label?: string,
  path: string,
  type: string;
}

export function useValidation(): ReturnType {
  const { t } = useTranslation()
  const config = {
    mixed: {
      required: t('common:validations.required'),
      notType: (data: YupValidationError) => {
        switch (data.type) {
          case 'number':
            return t('common:validations.number')
          default:
            return t('common:validations.wrong-type')
        }
      }
    },
    string: {
      email: t('common:validations.email'),
      min: ({ min }: { min: number }) => t('common:validations.min', { min }),
    },
    number: {
      min: ({ min }: { min: number }) => t('common:validations.min-value', { min }),
      max: ({ max }: { max: number }) => t('common:validations.max-value', { max })
    }
  }

  return {
    config
  }
}
