import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useAutoLogout = (timeout: number) => {
  const { push, pathname } = useRouter()
  const [showLoggedOutMessage, setShowLoggedOutMessage] = useState<boolean>(false)

  function checkForInactivity() {
    const expireTime = localStorage.getItem('expireTime')
    if (Number(expireTime) < Date.now()) {
      push('/auth/sign-out')
      setShowLoggedOutMessage(true)
    }
  }

  function updateExpireTime() {
    const updatedExpireTime = Date.now() + 30 * 60 * 1000
    localStorage.setItem('expireTime', updatedExpireTime.toString())
  }

  useEffect(() => {
    const currentPath = pathname
    if (currentPath.includes('/auth') === false) {
      window.addEventListener('mousemove', updateExpireTime)
      window.addEventListener('keydown', updateExpireTime)
      window.addEventListener('mousedown', updateExpireTime)
      window.addEventListener('scroll', updateExpireTime)
      window.addEventListener('touchstart', updateExpireTime)
    }
    const interval = setInterval(() => {
      if (currentPath.includes('/auth') === false) {
        checkForInactivity()
      }
    }, timeout)
    return () => {
      window.removeEventListener('mousemove', updateExpireTime)
      window.removeEventListener('keydown', updateExpireTime)
      window.removeEventListener('mousedown', updateExpireTime)
      window.removeEventListener('scroll', updateExpireTime)
      window.removeEventListener('touchstart', updateExpireTime)
      clearInterval(interval)
    }
  }, [pathname])
  return { showLoggedOutMessage, setShowLoggedOutMessage }
}

export default useAutoLogout
