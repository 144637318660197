import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'
import * as SDK from '~/sdk'

export interface Request {
  sessionId: string
  target: SDK.Internal.V1.Auth.BankId.TargetUnit
}

export interface Response {
  signed: boolean
}

export async function post(payload: BaseExchange<Request>): Promise<BaseResponse<Response>> {
  return await API.post<BaseExchange<Request>, BaseResponse<Response>>('internal/v1/company/applications/business-loan/session', payload)
}
