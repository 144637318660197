import { BaseResponse, EmptyBaseResponse, BaseExchange } from '~/sdk/shared'
import API from '~/sdk/client'

interface SetEmailRequest {
  email: string
}

interface SetCodeResponse {
  accessToken: string
  tokenType: string
  expiresAt: string
}

interface SetCodeRequest {
  code: number
}


export async function setEmail(payload: BaseExchange<SetEmailRequest>): Promise<EmptyBaseResponse> {
  return await API.put<BaseExchange<SetEmailRequest>, EmptyBaseResponse>('internal/v1/user/email/verify', payload)
}

export async function code(payload: BaseExchange<SetCodeRequest>): Promise<BaseResponse<SetCodeResponse>> {
  return await API.post<BaseExchange<SetCodeRequest>, BaseResponse<SetCodeResponse>>('internal/v1/user/email/verify', payload)
}
