import { BaseResponse } from '~/sdk/shared'
import API from  '~/sdk/client'

export interface Entity {
  companyType: 'AB' | 'EF' | 'HB/KB' | 'Other' | 'INDIVIDUAL'
  latestReportDate: string | null
  reportAge: number | null
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/credit-report')
}

