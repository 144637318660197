import { BaseResponse, Money } from '~/sdk/shared'
import API from '~/sdk/client'

export type Entity = {
  escrow: Money,
  clearence: Money,
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/payments/balance')
}
