import { invoicesApi } from '~/services/invoices.service';
import { settingsApi } from '~/services/settings.service';
import { productApi } from '~/services/product.service';
import { customersApi } from '~/services/customers.service';
import { companyUsersApi } from '~/services/company.users.service';
import { guarantorsApi } from '~/services/guarantors.service';
import { applicationsApi } from '~/services/applications.service';
import { useAppDispatch } from '~/hooks/useAppDispatch';

import useLocalStorage from '~/hooks/useLocalStorage';
import { resetAll as resetApplicationStates } from '~/store/actions';
import useAppState from './useApp';
import { companyBoardMembersApi } from '~/services/company.board-members.service';
import { resetUserState } from '~/store/user/userSlice';
import { resetGuarantorState } from '~/store/guarantors/guarantorsSlice';
import { resetSmoothAppState } from '~/store/smoothApp/smoothApp';
import { resetInvoicesState } from '~/store/invoices/invoicesSlice';
import { resetApplicationSubmitState } from '~/store/applicationSubmit/applicationSubmitSlice';

const useApplicationReset = () => {

  const dispatch = useAppDispatch();

  const appState = useAppState();

  const blFormcCache = useLocalStorage('BUSINESS_LOAN');
  const idFormCache = useLocalStorage('INVOICE_DISCOUNTING');
  const siFormCache = useLocalStorage('SELL_INVOICE');

  const appContext = useLocalStorage('appContext');
  const refContext = useLocalStorage('refContext');
  const refProduct = useLocalStorage('refProduct');
  const companyId = useLocalStorage('companyId');

  // clear local storage for applications
  function resetFormCache() {
    blFormcCache.remove();
    idFormCache.remove();
    siFormCache.remove();
  }

  function resetRefContext() {
    refContext.remove();
  }

  function resetAppContext() {
    appContext.remove();
  }

  function resetRefProduct() {
    refProduct.remove();
  }

  function resetAppState(companyId?: number) {
    appState.reset(companyId);
  }

  function resetUser() {
    dispatch(resetUserState());
  }

  function resetInvoices() {
    dispatch(resetInvoicesState());
  }

  function resetProducts() {
    dispatch(productApi.util.invalidateTags([
      { type: 'bl' },
      { type: 'si' },
      { type: 'id' },
    ]));
  }

  function resetApplicationSubmit() {
    dispatch(resetApplicationSubmitState());
  }

  // clear all cached RTK Query data
  function resetCache() {

    // cleanup cached data
    dispatch(invoicesApi.util.invalidateTags([{ type: 'Invoices', id: 'LIST' }]));
    dispatch(customersApi.util.invalidateTags([{ type: 'Customers' }]));
    dispatch(companyUsersApi.util.invalidateTags([{ type: 'CompanyUsers' }]));
    dispatch(applicationsApi.util.invalidateTags([{ type: 'Applications', id: 'LIST' }]));
    dispatch(guarantorsApi.util.invalidateTags([{ type: 'Guarantors', id: 'LIST' }]));
    dispatch(companyBoardMembersApi.util.invalidateTags([{ type: 'BoardMembers', id: 'LIST' }]));

    dispatch(resetInvoicesState());

    dispatch(settingsApi.util.invalidateTags([
      { type: 'BankAccount' },
      { type: 'Bookkeeping' },
      { type: 'AutoGiro' }
    ]));

    resetProducts();
  }

  // set all store slices to their initialState
  function resetStates() {
    dispatch(resetGuarantorState());
    dispatch(resetSmoothAppState());
    dispatch(resetApplicationStates());
  }

  /**
   * Clean up cache, sweep store slices and do whatever
   * is needed to start with a clean slate.
   *
   * Hard reset when logging out, sweeping everything,
   * soft reset when switching company.
   */
  function resetAll(type: 'soft' | 'hard' = 'soft') {

    resetFormCache();
    resetCache();
    resetStates();
    resetApplicationSubmit();

    if (type === 'hard') {

      resetAppState();
      resetRefContext();
      resetAppContext();
      resetRefProduct();
      resetRefContext();

      // cleanup company id
      companyId.remove();
    }
  }

  return {
    resetAll,
    resetCache,
    resetFormCache,
    resetStates,
    resetAppState,
    resetRefContext,
    resetRefProduct,
    resetAppContext,
    resetUser,
    resetProducts,
    resetInvoices,
    resetApplicationSubmit
  };
};

export default useApplicationReset;
