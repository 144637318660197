import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

interface CreateRequest {
  identityNumber: string
  name?: string
}

interface CreateResponse {
  identityNumber: string
  name: string,
  phone: string,
  email: string,
  address1: string,
  address2: string,
  zipCode: string,
  city: string,
  countrycode: string,
  country: string,
  createdAt: string
  updatedAt: string
}

export async function create(payload: BaseExchange<CreateRequest>): Promise<BaseResponse<CreateResponse>> {
  return await API.post<BaseExchange<CreateRequest>, BaseResponse<CreateResponse>>('internal/v2/user/companies', payload)
}
