
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AuthProvider } from '~/context/AuthContext';
import { useValidation } from '~/hooks/validation';
import type { AppProps } from 'next/app';
import React, { ReactNode, useEffect } from 'react';
import { setLocale } from 'yup';
import PiwikProProvider from '@piwikpro/next-piwik-pro';
import { PIWIK_CONTAINER_ID, PIWIK_CONTAINER_URL, ENABLED as PiwikEnabled } from 'config/piwik';
import { Provider } from 'react-redux';
import store from '~/store/index';
import { Banner } from '~/components/Banner/Banner';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import useAutoLogout from '~/hooks/useAutoLogout';
import GlobalStateInitializer from '~/context/GlobalStateInitializer';
import { SentryFeedback } from '~/components/SentryFeedback/SentryFeedback';
import { SentryFeedbackButton } from '~/components/SentryFeedback/SentryFeedbackButton';
import Toaster from '~/components/Toaster/Toaster';
import useModal from '~/hooks/useModal';
// import global styles
import '../styles/globals.scss';
const Tracking: React.FC<{
    children: ReactNode;
}> = ({ children }) => {
    if (PiwikEnabled && PIWIK_CONTAINER_ID && PIWIK_CONTAINER_URL) {
        return (<PiwikProProvider containerUrl={PIWIK_CONTAINER_URL} containerId={PIWIK_CONTAINER_ID}>
        {children}
      </PiwikProProvider>);
    }
    return <>{children}</>;
};
function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    const { config } = useValidation();
    const { query, pathname, asPath } = useRouter();
    const { t } = useTranslation();
    const modal = useModal({
        isModal: true
    });
    // automatically logout when not active
    const { showLoggedOutMessage, setShowLoggedOutMessage } = useAutoLogout(60000);
    useEffect(() => {
        modal.onOpen();
    }, [query]);
    useEffect(() => {
        setLocale(config);
        if (asPath.includes('company_id=')) {
            sessionStorage.setItem('redirectToInvoices', asPath);
        }
    }, []);
    return (<Provider store={store}>
      <Tracking>
        <AuthProvider>
          <GlobalStateInitializer>
            <div>
              <div className="text-base antialiased font-body" id="app-content">
                <Component {...pageProps}/>
                {/* hide sentry feed back temporarily */}
                {/* {!pathname.includes('/auth') && <SentryFeedbackButton />} */}
              </div>
              <Banner id="auto-logout" open={pathname.includes('/auth') && showLoggedOutMessage} position="top" type="message" showOnce={false} autoClose={false} onClose={() => setShowLoggedOutMessage(false)}>
                <p>{t('cta:automatically-logged-out')}</p>
              </Banner>
              <Toaster />
              <SentryFeedback />
            </div>
          </GlobalStateInitializer>
        </AuthProvider>
      </Tracking>
    </Provider>);
}
const __Next_Translate__Page__18e7a28ab5e__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__18e7a28ab5e__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  