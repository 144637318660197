import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export interface Entity {
  id: number,
  identityNumber?: string,
  mailPreferences: boolean,
  name: string,
  signatory: boolean,
  phone?: string,
  email?: string,
}

export interface InvitePayload {
  email: string,
  phone?: string,
}

export async function get(): Promise<BaseResponse<Entity[]>> {
  return await API.get('internal/v1/company/users')
}

export async function show(id: string): Promise<BaseResponse<Entity>> {
  return await API.get(`internal/v1/company/users/${id}`)
}

export async function post(payload: BaseExchange<InvitePayload>): Promise<BaseResponse<Entity>> {
  return await API.post('internal/v1/company/users/invite', payload)
}

export * as Invite from './invite'
