import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export enum TargetUnit {
  Other = 'OTHER',
  This = 'THIS',
}

export interface Request {
  target: TargetUnit
  ssn: string
}
export interface Response {
  autoStartUrl: string
  sessionId: string
}

export async function post(payload: BaseExchange<Request>): Promise<BaseResponse<Response>> {
  return await API.post<BaseExchange<Request>, BaseResponse<Response>>('internal/v1/auth/bank-id', payload)
}

export * as Session from './session'
