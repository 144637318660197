var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2b8cc6504b203a5927be99de725577b0c5f21321"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Feedback } from '@sentry/nextjs';
import { get as getUser } from 'sdk/internal/v1/user/me';
import { getCookie } from '~/utils/cookies';


const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

function hasToken() {  
  return !!getCookie('_auth.token');
}

if (hasToken()) {
  const response = await getUser();
  const user = response.data.data;
  const company = user.companies.find((company) => company.default === 1);

  Sentry.setUser({
    id: user.id,
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
  });

  if (company) {
    Sentry.setTags({
      company_id: company.id,
      company_org_no: company.identityNumber,
      company_name: company.name,
    });
  }
}

Sentry.init({
  dsn: SENTRY_DSN || 'https://50bede70e768410c9c6852295d2f6ec3@o325060.ingest.sentry.io/6308647',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  debug: false,
  integrations: [

  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
