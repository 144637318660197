import React, { ChangeEvent, MouseEvent, FocusEvent, forwardRef, useEffect, useState } from 'react'

import { Input, InputProps } from '~/components/Form'
import { SearchIcon } from '@monto/react-common-ui'
import { useOnClickOutside } from '~/hooks/useOnClickOutside'

type WithAsyncFn = (query: string) => Promise<string[]>

export interface AutocompleteProps extends InputProps {
  onSelected: (value: string) => void
  items: WithAsyncFn | string[]
  minLength?: number,
}

const AutocompleteElement: React.FC<AutocompleteProps> = ({ onChange, onBlur, onSelected, minLength = 3, items: propItems, ...rest }) => {
  const [selected, setSelected] = useState<string>()
  const [items, setItems] = useState<string[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')

  const { ref } = useOnClickOutside<HTMLDivElement>(() => setOpen(false))

  useEffect(() => {
    if (Array.isArray(propItems)) setItems(propItems)
    else propItems(query).then(result => {
      setItems(result)
    })
  }, [propItems])

  useEffect(() => {
    setOpen(items.length > 0)
  }, [items])

  useEffect(() => {
    if (selected) {
      setQuery(selected)
      setOpen(false)
    }
  }, [selected])

  function onChangedHandler(event: ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value)

    if (onChange) onChange(event)
  }

  function onBlurHandler(event: FocusEvent<HTMLInputElement>) {
    setQuery(event.target.value)

    if (onBlur) onBlur(event)
  }

  function onSelectedHandler(event: MouseEvent<HTMLButtonElement>, value: string) {
    event.preventDefault()

    setQuery(value)
    onSelected(value)
    setOpen(false)
  }

  return (
    <div className="relative" ref={ref}>
      <Input
        icon={() => <SearchIcon className="text-gray-600" />}
        onFocus={() => setOpen(true)}
        onChange={onChangedHandler}
        onBlur={onBlurHandler}
        autoComplete="off"
        value={query}
        {...rest}
      />

      {open && (
        <div className="absolute bottom-0 left-0 w-full translate-y-full bg-white shadow-medium rounded-small">
          {items.map(item => (
            <button
              className="block w-full px-4 py-3 text-left hover:bg-gray-100"
              onClick={(event) => onSelectedHandler(event, item)}
              type="button"
              key={item}
            >
              {item}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(function Autocomplete(props, ref) {
  return (
    <AutocompleteElement otherRef={ref} {...props} />
  )
})
