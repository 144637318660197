import { ReactNode, useEffect } from 'react';
import useLocalStorage from '~/hooks/useLocalStorage';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { setAppContext, setCurrentCompanyId, setIsConnected, setRefContext } from '~/store/application/applicationSlice';
import useAppState from '~/hooks/useApp';
import useApplicationReset from '~/hooks/useApplicationReset';
import { useMe } from '~/hooks/useMe';
import { setFirstConnectionSuccess, syncProvider } from '~/store/providers/providersSlice';
import { useRouter } from 'next/router';
import { getAuthenticatedCompany } from '~/sdk/internal/v1/company/authenticated-company';
import useToaster from '~/hooks/useToaster';
import { useSelector } from 'react-redux';
import { RootState } from '~/store';
import useTranslation from 'next-translate/useTranslation';

/**
 * Set global state, or set cookies / localStorage in this wrapper
 */
const GlobalStateInitializer: React.FC<{ children: ReactNode }> = ({ children }) => {

  const router = useRouter();
  const dispatch = useAppDispatch();
  const appState = useAppState();
  const user = useMe();
  const toaster = useToaster();

  const appContext = useLocalStorage('appContext');
  const refContext = useLocalStorage('refContext');
  const refProduct = useLocalStorage('refProduct');
  const companyId = useLocalStorage('companyId');
  const useOnboarding = useLocalStorage('useOnboarding');
  const providers = useSelector((state: RootState) => state.providers);
  const { t } = useTranslation();

  const resetApplication = useApplicationReset();

  const checkAuthenticatedCompany = async () => {

    try {

      const { data } = await getAuthenticatedCompany();

      useOnboarding.set(data.data.showOnboarding);
      appState.setOnboarding({ useOnboarding: data.data.showOnboarding });

      const connectedProvider = data.data.providers.find((provider) => provider.enabled);
      dispatch(setIsConnected(!!connectedProvider));

      appState.updateCurrentCompany({
        companyType: data.data.type,
      });

      // start syncing when company has connected erp
      if (connectedProvider) {
        dispatch(syncProvider());

        // is this the first successful connection?
        if (connectedProvider.firstSyncOngoing === true && connectedProvider.lastsync === null) {
          dispatch(setFirstConnectionSuccess(true));
        }
      }
    } catch (e) {

      toaster.show({
        type: 'error',
        title: t('toasts:company-fetch-error.title'),
        message: t('toasts:company-fetch-error.message'),
        buttons: [
          toaster.commonButtons('try-again', () => checkAuthenticatedCompany()),
          toaster.commonButtons('contact-us')
        ]
      });
    }
  }

  useEffect(() => {

    // no company...
    if (!user.state.currentCompany?.id) return;

    appState.setStatus('loading');

    // set the global company id
    appState.setCurrentCompanyId(user.state.currentCompany?.id);

    // check if we need to do a hard refresh
    if (user.state.currentCompany?.id !== companyId.get()) {

      resetApplication.resetAll('soft');

      // update global company id.
      dispatch(setCurrentCompanyId(user.state.currentCompany.id));
    }

    // check if user needs things such as onboarding
    checkAuthenticatedCompany();

    // add the companyId to local storage
    companyId.set(user.state.currentCompany?.id);

    appState.setStatus('ready');

  }, [user.state.currentCompany]);

  useEffect(() => {

    // skip when on login page.
    if (router.pathname.includes('/auth')) return;

    if (providers.syncIsReady) {
      toaster.show({
        title: t('toasts:sync-ready.title'),
        type: 'success',
        message: t('toasts:sync-ready.message'),
        buttons: [
          toaster.commonButtons()
        ]
      });
    }
  }, [providers.syncIsReady]);

  // if user switches company, make sure that this change is
  // also reflected to open applications in other browser tabs.
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'companyId') {
        resetApplication.resetUser();
        resetApplication.resetAll('soft');
        user.refetch();
      }
    };

    if (typeof window !== undefined) {

      // listen for changes in local storage.
      window.addEventListener('storage', handleStorageChange);

      // Cleanup listener on unmount
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, []);


  useEffect(() => {

    if (!router.query) return;

    // set product
    if (router.query.product) refProduct.set(router.query.product);

    // set app context and ref context based on query params
    if (router.query.broker == 'lendo') {
      refContext.set('lendo');
      dispatch(setRefContext('lendo'));
    }

    if (router.query.bidder == 'fortnox') {
      appContext.set('fortnox');
      dispatch(setAppContext('fortnox'));
    }

    if (router.query.bidder == 'capcito') {
      appContext.set('capcito');
      dispatch(setAppContext('capcito'));
    }

  }, [router.query, dispatch, appState.data.currentCompanyId]);

  return <>{children}</>;
};

export default GlobalStateInitializer;
