import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseExchange, BaseResponse } from '~/sdk/shared';
import baseQuery from './base-query';

export interface CompanyUserEntity {
  id: number,
  identityNumber?: string,
  mailPreferences: boolean,
  name: string,
  signatory: boolean,
  phone?: string,
  email?: string,
}

export interface InvitePayload {
  email: string,
  phone?: string,
}

// The users associated with a company
export const companyUsersApi = createApi({
  reducerPath: 'companyUsersApi',
  tagTypes: ['CompanyUsers'],
  baseQuery,
  endpoints: (builder) => ({
    getCompanyUsers: builder.query<BaseExchange<CompanyUserEntity[]>, void>({
      query: () => ({
        url: '/internal/v1/company/users',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<CompanyUserEntity[]> => {
        return { data: response?.data } as BaseExchange<CompanyUserEntity[]>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'CompanyUsers' }] : [];
      },
    }),
    getCompanyUserById: builder.query<BaseExchange<CompanyUserEntity>, string>({
      query: (id) => ({
        url: `/internal/v1/company/users/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => {
        return result ? [{ type: 'CompanyUsers' }] : [];
      },
    }),
    inviteCompanyUser: builder.mutation<BaseExchange<InvitePayload>, BaseExchange<CompanyUserEntity>>({
      query: (payload) => ({
        url: '/internal/v1/company/users/invite',
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCompanyUsersQuery,
  useGetCompanyUserByIdQuery,
  useInviteCompanyUserMutation,
} = companyUsersApi;
