import { BaseExchange, BaseFilterRequest, buildRequestQuery, EmptyBaseResponse, Money, PaginatedBaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'
import { AxiosResponse } from 'axios'

export interface Entity {
  id: number
  name: string
  usedAsSecurity: boolean
  loanLimit: Money
  totalAccountReceivables: Money
  numberOfOpenInvoices: number
}

export interface Filter {
  hasOpenInvoices?: ['EQUAL', 1]
}

export async function get(request?: BaseFilterRequest<Filter>): Promise<AxiosResponse<PaginatedBaseResponse<Entity>>> {
  return await API.get(`internal/v1/company/manage-securities${buildRequestQuery(request)}`)
}

export interface Request {
  companyIds: number[]
}

export async function post(payload: BaseExchange<Request>): Promise<EmptyBaseResponse> {
  return await API.post('internal/v1/company/manage-securities', payload)
}
