import { formatDistance, intlFormat, differenceInDays } from 'date-fns';
import { format as formatDate } from 'date-fns';
import { endOfToday, addDays, subDays, isBefore, isToday as dateIsToday} from 'date-fns';
import { enUS, sv } from 'date-fns/locale';

/**
 * Wrapper for fns date library
 */
export function date(date: string | number | Date, lang?: string) {

  const locale = (lang === 'sv') ? sv : enUS;

  /**
   * Displays a relative date, eg '5 days ago'
   * @returns string
   */
  function toRelative() {
    return formatDistance(new Date(date), new Date(), { addSuffix: true, locale });
  }

  /**
   * Format a date, defaults to yyyy-MM-dd, eg. 1980-09-03
   * @param format
   * @param options
   * @returns string
   */
  function format(format = 'yyyy-MM-dd', options?: {
    locale?: globalThis.Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  }) {
    return formatDate(new Date(date), format, options);
  }

  /**
   * Displays a localized human readable date, eg. 3 september 1980
   * @returns string
   */
  function humanized() {
    return intlFormat(new Date(date), {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }, {
      locale: lang
    });
  }

  /**
   * Is given date today?
   */
  function isToday() {
    return dateIsToday(new Date(date));
  }

  /**
   * Is given date in the past? This is on a day basis,
   * so yesterday will return true, and today false
   */
  function isPast() {
    const today = endOfToday();
    const yesterday = addDays(today, -1);
    return isBefore(new Date(date), yesterday);
  }

  /**
   * Is given date before X days in the future?
   */
  function isDueInXDays(days: number) {
    const diff = differenceInDays(new Date(date), new Date());
    return diff >= 0 && diff <= days;
  }

  /**
   * Is given date less than X days ago?
   */
  function isLessThanXDaysOld(days: number) {

    // Calculate the date X days ago from now
    const xDaysAgo = subDays(new Date(), days);

    // Check if the given date is after 'xDaysAgo'
    return isBefore(xDaysAgo, new Date(date));
  }

  return {
    toRelative,
    format,
    humanized,
    isPast,
    isDueInXDays,
    isToday,
    isLessThanXDaysOld
  }
}
