import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseExchange } from '~/sdk/shared';
import baseQuery from './base-query';
import { Entity as BusinessLoanEntity } from 'sdk/internal/v2/company/products/business-loan';
import { Entity as FactoringEntity } from 'sdk/internal/v2/company/products/factoring';
import { Entity as InvoiceDiscountingEntity } from 'sdk/internal/v2/company/products/invoice-discounting';

// Define the endpoints here
export const productApi = createApi({
  reducerPath: 'productApi',
  tagTypes: ['bl', 'id', 'si'],
  baseQuery,
  endpoints: (builder) => ({
    getBusinessLoan: builder.query<BaseExchange<BusinessLoanEntity>, void>({
      query: () => ({
        url: '/internal/v2/company/products/business-loan',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<BusinessLoanEntity> => {
        return { data: response?.data } as BaseExchange<BusinessLoanEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'bl' }] : [];
      },
    }),
    getFactoring: builder.query<BaseExchange<FactoringEntity>, void>({
      query: () => ({
        url: '/internal/v2/company/products/factoring',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<FactoringEntity> => {
        return { data: response?.data } as BaseExchange<FactoringEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'si' }] : [];
      },
    }),
    getInvoiceDiscounting: builder.query<BaseExchange<InvoiceDiscountingEntity>, void>({
      query: () => ({
        url: '/internal/v2/company/products/invoice-discounting',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<InvoiceDiscountingEntity> => {
        return { data: response?.data } as BaseExchange<InvoiceDiscountingEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'id' }] : [];
      },
    }),
  }),
});

export const {
  useGetBusinessLoanQuery,
  useGetFactoringQuery,
  useGetInvoiceDiscountingQuery
} = productApi;
