import { BaseExchange, BaseResponse } from 'sdk/shared'
import API from '~/sdk/client'
import * as SDK from 'sdk'

export interface StorePersonalIdRequest {
  target: SDK.Internal.V1.Auth.BankId.TargetUnit
  ssn: string
}

export interface StorePersonalIdResponse {
  autoStartUrl: string
  sessionId: string
}

export interface StorePersonalIdSessionRequest {
  target: SDK.Internal.V1.Auth.BankId.TargetUnit
  sessionId: string
}

export interface StorePersonalIdSessionResponse {
  signed: boolean
}

export async function store(payload: BaseExchange<StorePersonalIdRequest>): Promise<BaseResponse<StorePersonalIdResponse>> {
  return await API.post('/internal/v1/user/personal-id', payload)
}

export async function session(payload: BaseExchange<StorePersonalIdSessionRequest>): Promise<BaseResponse<StorePersonalIdSessionResponse>> {
  return await API.post('/internal/v1/user/personal-id/session', payload)
}
