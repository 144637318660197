import { BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export type Entity = {
  receiver: string,
  bankgiro: string,
  paymentReference: string,
}

export async function get(): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/payments/repayment')
}
