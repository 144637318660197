export enum RequirementType {
  ConnectERP = 'CONNECT_ERP',
  Guarantor = 'GUARANTOR',
  GuarantorLimited = 'GUARANTOR_LIMITED',
  RepaymentAccount = 'REPAYMENT_ACCOUNT',
  PayoutAccount = 'PAYOUT_ACCOUNT',
  FinancialDocuments = 'FINANCIAL_DOCUMENTS',
  KYC = 'KYC',
  PersonalIDVerified = 'PERSONAL_ID_VERIFIED',
  PhoneNumber = 'PHONE_NUMBER'
}

export enum RequirementCondition {
  BeforeApplication = 'BEFORE_APPLICATION',
  BeforePayout = 'BEFORE_PAYOUT'
}

export type Requirements = {
  type: RequirementType
  when: RequirementCondition.BeforeApplication | RequirementCondition.BeforePayout
  group: string
}

export interface HasRequirements {
  requirements: Requirements[]
}
