import { BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export interface Entity {
  name: string
  identityNumber: string
}

export async function get(): Promise<BaseResponse<Entity[]>> {
  return await API.get('/internal/v1/universe/companies')
}
