import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

export type ToasterButton = {
  label: string,
  cta?: () => void,
  type: ToasterType
}

type ToasterType = 'primary' | 'secondary';

export type Toast = {
  type: 'info' | 'warning' | 'success' | 'error'
  title: string
  message: string | ReactElement
  buttons: ToasterButton[]
  durationInSeconds?: number
}

const baseToast: Partial<Toast> = {
  type: 'info',
  title: 'Set a toaster title',
  message: 'Set a toaster message',
  durationInSeconds: 10,
  buttons: []
}

export type Toasts = Record<string, Toast>

type InitialState = {
  toasts: Record<string, Toast>
}

const initialState: InitialState = {
  toasts: {}
};

const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    resetToasterState: () => initialState,
    showToast: (state, action: PayloadAction<Toast>) => {
      state.toasts[uuidv4()] = {...baseToast, ...action.payload};
    },
    removeToast: (state, action: PayloadAction<string>) => {
      delete state.toasts[action.payload];
    }
  },
});

export const {
  showToast, removeToast
} = toasterSlice.actions;

export default toasterSlice.reducer;
