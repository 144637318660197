import React, { forwardRef, useEffect, useState } from 'react';
import { CloseIcon } from '@monto/react-common-ui';
import ReactDOM from 'react-dom';

export type DialogPosition = 'center' | 'bottom';
export type DialogSize = 'small' | 'medium' | 'large' | 'custom' | 'auto';
export type DialogType = 'warning' | 'default';

export interface DialogProps extends React.PropsWithChildren {
  onClose: () => void,
  children: React.ReactNode,
  position?: DialogPosition,
  className?: string,
  size?: DialogSize,
  type?: DialogType,
  labelledBy?: string,
  appendToID?: string // The dialog will be appended to this element (instead of document.body)
}

export type DialogRef = HTMLDialogElement;

const SimpleDialog = forwardRef<DialogRef, DialogProps>(function DialogElement({
  children,
  onClose,
  className = '',
  type = 'default',
  size = 'auto',
  appendToID,
  labelledBy=''
}, ref) {

  const [appendTarget, setAppendTarget] = useState<Element | null>(null);

  useEffect(() => {
    const target = appendToID ? document.getElementById(appendToID) : document.body;
    setAppendTarget(target);
  }, [appendToID]);

  // If appendTarget is not yet set, don't render the portal
  if (!appendTarget) return null;

  return ReactDOM.createPortal(
    <dialog
      ref={ref}
      data-component="simple-dialog"
      className={'simple-dialog ' + className}
      data-type={type}
      data-size={size}
      aria-modal="true"
      aria-labelledby={labelledBy}
    >
      <div className="dialog-inner">
        {children}
      </div>
      <button className="close" onClick={onClose} formMethod="dialog">
        <CloseIcon />
      </button>
    </dialog>,
    appendTarget || document.body
  );
});

export default SimpleDialog;
