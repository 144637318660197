import { createSlice, createAction, createSelector } from '@reduxjs/toolkit';
import { ProductKeys, InvestmentTypeType } from '~/hooks/useOnboarding';
import { DataLayer } from '@piwikpro/react-piwik-pro';
import { Event as PiwikEvent } from '~/enums/gtm';
import { RootState } from '..';
import { CompanyType } from '~/sdk/internal/v1/company/authenticated-company';

export type AmountsType = {
  [key in ProductKeys]: number
}

export interface PiwikEventDataType {
  product: string,
  companyId?: number,
  page: string,
  action: string,
  description?: string,
}

export interface CompanyOnboardingType {
  useOnboarding: boolean | undefined,
  skipProvider: boolean,
  availableAmounts: AmountsType,
  desiredAmount: number,
  investmentType?: InvestmentTypeType,
}

export interface CompanyState {
  refContext?: 'lendo' | 'fortnox',
  appContext?: 'capcito' | 'fortnox',
  onboarding: CompanyOnboardingType
  excludedProduct?: ProductKeys,
  hasSellableInvoices: boolean,
  companyType?: CompanyType,
  hasApplications: boolean,
  isConnected: boolean, // currently not working
}

export interface ApplicationState {
  status: 'loading' | 'ready' | 'init'
  currentCompanyId: number | undefined,
  piwik: Record<PiwikEvent, PiwikEventDataType>,
  companyState: Record<number, CompanyState>
  showFeedbackDialog: boolean
}

const initialCompanyState = {
  hasSellableInvoices: false,
  hasApplications: false,
  refContext: undefined,
  appContext: 'capcito',
  excludedProduct: undefined,
  isConnected: false,
  companyType: undefined,
  onboarding: {
    useOnboarding: undefined,
    skipProvider: false,
    investmentType: 'one-time',
    desiredAmount: 0,
    availableAmounts: {
      'si': 0,
      'bl': 0,
      'id': 0
    },
  }
} as CompanyState;

const initialState: ApplicationState = {
  status: 'init',
  currentCompanyId: undefined,
  piwik: {} as Record<PiwikEvent, PiwikEventDataType>,
  companyState: {} as Record<number, CompanyState>,
  showFeedbackDialog: false,
};

// action to update the sync status
export const setCurrentCompanyId = createAction<number | undefined>('application/setCurrentCompanyId');
export const resetAppState = createAction<number | undefined>('application/resetAppState');
export const setPiwik = createAction<{ event: PiwikEvent, payload: Partial<PiwikEventDataType> }>('application/setPiwik');
export const setAppContext = createAction<'capcito' | 'fortnox'>('application/setAppContext');
export const resetAppContext = createAction('application/resetAppContext');
export const setRefContext = createAction<'lendo' | 'fortnox'>('application/setRefContext');
export const resetRefContext = createAction('application/resetRefContext');
export const setOnboarding = createAction<Partial<CompanyOnboardingType>>('application/setOnboarding');
export const setExcludedProduct = createAction<ProductKeys | undefined>('application/setExcludedProduct');
export const setLoadingState = createAction<'loading' | 'init' | 'ready'>('application/setLoadingState');
export const setHasSellableInvoices = createAction<boolean>('application/setHasSellableInvoices');
export const setHasApplications = createAction<boolean>('application/setHasApplications');
export const setIsConnected = createAction<boolean>('application/setIsConnected');
export const updateCompany = createAction<Partial<CompanyState>>('application/updateCompany');
export const setShowFeedbackDialog = createAction<boolean>('application/showFeedbackDialog');

// Selector to get the current CompanyState
export const selectCurrentCompanyState = createSelector(
  (state: RootState) => state.application,
  (applicationState) => {
    const currentCompanyId = applicationState.currentCompanyId;
    if (currentCompanyId === undefined) {
      return null;
    }
    return applicationState.companyState[currentCompanyId];
  }
);

const getOrInitializeCompanyState = (state: ApplicationState) => {
  const currentCompanyId = state.currentCompanyId;
  if (!currentCompanyId) return null; // No company ID

  if (!state.companyState[currentCompanyId]) {
    state.companyState[currentCompanyId] = initialCompanyState;
  }

  return state.companyState[currentCompanyId];
};

const updateUseOnboarding = (state: CompanyState) => {
  return !state.hasSellableInvoices && !state.hasApplications;
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(resetAppState, (state, action) => {

      // only delete the state for this company when ID was supplied
      if (action.payload) delete state.companyState[action.payload];
      else {
        // or reset everything
        return initialState;
      }
    })
    builder.addCase(setLoadingState, (state, action) => {
      state.status = action.payload;
    })
    builder.addCase(setShowFeedbackDialog, (state, action) => {
      state.showFeedbackDialog = action.payload;
    })
    builder.addCase(resetAppContext, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;
      companyState.appContext = undefined;
    })
    builder.addCase(resetRefContext, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;
      companyState.refContext = undefined;
    })
    builder.addCase(setHasApplications, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;
      companyState.hasApplications = action.payload;

      // update onboarding state.
      companyState.onboarding.useOnboarding = updateUseOnboarding(companyState);
    })
    builder.addCase(setIsConnected, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;
      companyState.isConnected = action.payload;
    })
    builder.addCase(updateCompany, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;
      if (state.currentCompanyId) {
        state.companyState[state.currentCompanyId] = {...companyState, ...action.payload};
      }
    })
    builder.addCase(setHasSellableInvoices, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;
      companyState.hasSellableInvoices = action.payload;

      // update onboarding state.
      companyState.onboarding.useOnboarding = updateUseOnboarding(companyState);
    })
    builder.addCase(setCurrentCompanyId, (state, action) => {

      state.currentCompanyId = action.payload;
      if (!action.payload) return;
      if (!state.companyState[action.payload]) {
        state.companyState[action.payload] = initialCompanyState;
      }
    })
    builder.addCase(setOnboarding, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;

      companyState.onboarding = { ...companyState.onboarding, ...action.payload };
    })
    builder.addCase(setAppContext, (state, action) => {

      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;

      companyState.appContext = action.payload;
    })
    builder.addCase(setRefContext, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;

      companyState.refContext = action.payload;
    })
    builder.addCase(setExcludedProduct, (state, action) => {
      const companyState = getOrInitializeCompanyState(state);
      if (!companyState) return;

      companyState.excludedProduct = action.payload;
    })
    builder.addCase(setPiwik, (state, action) => {

      // update state for this event
      state.piwik[action.payload.event] = { ...state.piwik[action.payload.event], ...action.payload.payload };

      // push event to Piwik
      DataLayer.push({
        event: action.payload.event,
        ...state.piwik[action.payload.event]
      });
    })
  }
});

export default applicationSlice.reducer;
