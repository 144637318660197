import useTranslation from 'next-translate/useTranslation';
import { useSelector } from 'react-redux';
import { RootState } from '~/store';
import { Toast, showToast, removeToast, ToasterButton } from '~/store/toaster/toasterSlice';
import useAppState from './useApp';
import { useAppDispatch } from './useAppDispatch';

type CommonButtons = 'contact-us' | 'try-again' | 'ok';

export default function useToaster() {

  const toasts = useSelector((state: RootState) => state.toaster);
  const dispatch = useAppDispatch();
  const appState = useAppState();
  const { t } = useTranslation();

  function show(toast: Toast) {
    dispatch(showToast(toast));
  }

  function remove(toastId: string) {
    dispatch(removeToast(toastId));
  }

  function commonButtons(type?: CommonButtons, cta?: () => void) {

    let button: ToasterButton;

    switch (true) {
      case type === 'try-again': button = {
        type: 'primary',
        label: t('toasts:button.try-again'),
        cta
      };
      break;
      case type === 'contact-us': button = {
        type: 'secondary',
        label: t('toasts:button.contact-us'),
        cta: () => {
          appState.feedbackDialogIsVisible(true);
        }
      };
      break;
      case type === 'ok':
      default: button = {
        type: 'primary',
        label: t('toasts:button.ok'),
        cta
      };
    }

    return button;
  }

  return {
    state: toasts,
    show,
    remove,
    commonButtons,
  };
}
