import { DeepMap, DeepPartial, FieldError, FieldValues, FormState } from 'react-hook-form'
import { Ref, useEffect, useState } from 'react';
import { forwardRef } from 'react';
import classNames from 'classnames'

export type FieldState = 'error' | 'info' | 'success' | undefined;

export interface FormGroupProps<T extends FieldValues> {
  htmlFor: keyof DeepMap<DeepPartial<T>, FieldError | boolean>
  children(state: FieldState): JSX.Element
  formState: FormState<T> | undefined | any
  help?: string
  inline?: boolean
  label: string
  labelClassName?: string
  className?: string
  ref?: Ref<any>;
}

export const Group = forwardRef(<T extends FieldValues>(props: FormGroupProps<T>, ref: Ref<any>) => {

  // props
  const { children, label, htmlFor, formState, className, inline = false, help } = props;

  const [errorMessage, setErrorMessage] = useState(formState?.errors[htmlFor]?.message as string);
  const [state, setState] = useState<FieldState>(undefined);

  function getErrorMessage(path: string) {
    let errors = formState.errors
    let isArrayValue = false
    const keys = path.split('.')

    // Loop through each level in error message path
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (errors as any && Array.isArray(errors[key])) { // If the key is for an array...
        errors = errors[key][parseInt(keys[i + 1])] // ...then use next key as array index
        i++;
        isArrayValue = true
      } else if (errors && typeof errors[keys[i]] == 'object') {
        errors = errors[key]
      }
    }

    if (!!errors.message || !!formState.errors[path]?.message) {
      setState('error');
    }

    return (isArrayValue) ? errors.message : formState.errors[path]?.message
  }

  useEffect(() => {

    const hasError = !!formState?.errors[htmlFor];
    const hasTouched = !!formState.touchedFields[htmlFor];
    const hasDirty = !!formState.dirtyFields[htmlFor];
    const hasInfo = help;

    if (!hasTouched && !hasDirty) return setState(undefined);
    if (hasInfo && hasDirty && !hasError) return setState('info');
    if (hasDirty && hasError) return setState('error');
    return setState(undefined);

  }, [formState]);

  useEffect(() => {

    if (state === 'error') {
      setErrorMessage(getErrorMessage(htmlFor as string) as string);
    }

  }, [state]);

  if (inline) {
    return (
      <div className={classNames(
        'form-group inline',
        className
      )}>
        <label>
          {children(state)}
          <span>
            {label}
          </span>
        </label>
      </div>
    )
  }

  return (
    <div className={classNames(
      'form-group block',
      className
    )}>
      <label htmlFor={htmlFor as string} title={label}>
        <span>{label}</span>
        {children(state)}
      </label>

      {help && (
        <div className="help-message" data-visible={state === 'info'}>
          {help}
        </div>
      )}

      <div className="error-message" data-visible={state === 'error'}>
        {errorMessage}
      </div>
    </div>
  )
})

Group.displayName = 'Group';
