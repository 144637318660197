import React, { FunctionComponent, forwardRef, useState, useEffect, InputHTMLAttributes, Ref, useRef, MouseEvent, ReactElement } from 'react';
import { HasClassName } from '@monto/react-common-ui';
import { FieldState } from '../Group/Group';
import { AlertIcon } from '~/components/Icons';

export interface InputProps extends HasClassName, InputHTMLAttributes<HTMLInputElement> {
  icon?: FunctionComponent<HasClassName>
  otherRef?: Ref<HTMLInputElement>
  state?: FieldState
  onIndicatorClick?: (event: MouseEvent<HTMLButtonElement>) => void
  prepend?: ReactElement | string,
  append?: ReactElement | string
}

const InputElement: React.FC<InputProps> = ({
  state,
  otherRef,
  className,
  type = 'text',
  icon: Icon,
  onIndicatorClick,
  prepend,
  append,
  ...props
}) => {

  const localRef = useRef<HTMLInputElement | null>(null);
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  function showIndicator(Icon: FunctionComponent<HasClassName> | undefined) {

    if (!Icon) return null;

    if (onIndicatorClick) {
      return (
        <div className="button-indicator">
          <button type="button" onClick={onIndicatorClick}>
            <Icon />
          </button>
        </div>
      )
    }

    return (
      <div className="button-indicator">
        <Icon/>
      </div>
    );
  }

  function checkFocus() {
    setHasFocus(document.activeElement === localRef.current);
  }

  useEffect(() => {
    document.addEventListener('focusin', checkFocus);
    document.addEventListener('focusout', checkFocus);

    return () => {
        document.removeEventListener('focusin', checkFocus);
        document.removeEventListener('focusout', checkFocus);
    };
  }, []);

  return (
    <div className="input-wrapper">
      {prepend && <span className="prepend">{prepend}</span>}
      <input
        ref={(instance: HTMLInputElement) => {
          localRef.current = instance;
          if (typeof otherRef === 'function') otherRef(instance);
        }}
        type={type}
        data-state={state}
        {...props}
      />
      { state === 'error' && !hasFocus && (
        <span className="alert-icon">
          <AlertIcon />
        </span>
      )}
      {append && <span className="append">{append}</span>}
      {showIndicator(Icon)}
    </div>
  )
};

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
  return (
    <InputElement
      otherRef={ref}
      {...props}
    />
  )
});
