import { BaseExchange, BaseResponse, EmptyBaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

interface CreateRequest {
  identityNumber: string
  name?: string
}

interface CreateResponse {
  identityNumber: string
  name: string
  createdAt: string
  updatedAt: string
}

export async function create(payload: BaseExchange<CreateRequest>): Promise<BaseResponse<CreateResponse>> {
  return await API.post<BaseExchange<CreateRequest>, BaseResponse<CreateResponse>>('internal/v1/user/companies', payload)
}

export async function setDefault(companyId: number): Promise<EmptyBaseResponse> {
  return await API.get(`internal/v1/user/companies/${companyId}/set-default`)
}
