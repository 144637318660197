import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Entity as MeEntity, Company as CompanyEntity } from 'sdk/internal/v1/user/me';
import { BaseResponse, BaseExchange } from '~/sdk/shared';
import { put as patch, get as getUser } from 'sdk/internal/v1/user/me';
import { get as getUniverseCompanies } from 'sdk/internal/v1/universe/companies';
import { validateSsn } from '@capcito/ui-validation';
import Router from 'next/router';
import { AxiosError } from 'axios';
import AppError from '~/utils/AppError';

// Define the type for a company with a non-optional name
type CompanyWithName = CompanyEntity & { name: string };

export type UserState = {
  data: MeEntity | null | undefined,
  currentCompany: Partial<CompanyEntity> | undefined,
  defaultCompanyId?: string,
  hasPhone: boolean,
  hasSsn: boolean,
  loading: boolean,
  error: string | undefined,
  status: 'init' | 'patching' | 'fetching' | 'ready' | 'error',
  companies: Partial<CompanyEntity>[]
};

const initialState: UserState = {
  data: undefined,
  currentCompany: undefined,
  defaultCompanyId: undefined,
  hasPhone: false,
  hasSsn: false,
  loading: false,
  error: undefined,
  status: 'init',
  companies: []
};

const mapToCompany = (company: Partial<CompanyEntity>) => {
  return {
    identityNumber: company.identityNumber,
    name: company.name,
    id: company.id,
    default: company.default,
    active: company.active,
    profileImageUrl: company.profileImageUrl
  };
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (arg, { dispatch, rejectWithValue }) => {
    try {

      const response: BaseResponse<MeEntity> = await getUser();
      let universeCompanies: Partial<CompanyEntity>[] = [];

      if (response.data.data.socialSecurityNumber) {
        try {
          universeCompanies = (await getUniverseCompanies()).data.data;
        } catch(error) {

          // If getUniverseCompanies fails, we report the error and continue with an empty array.
          new AppError(error, {
            where: 'fetchUser',
            function: 'getUniverseCompany'
          });

          universeCompanies = [];
        }
      }
      
      // 1. Iterate over internal companies
      const userCompanies = response.data.data?.companies.map(company => mapToCompany(company)) || []
      
      // 2. Iterate over universe companies.
      const filteredUniverseCompanies = universeCompanies.filter(company1 => !userCompanies.some(company2 => company1.identityNumber === company2.identityNumber))
        .map(company => mapToCompany(company)) || []

      // 3. Combine both company lists
      // Filter out companies without a name and assure TypeScript that `name` is not undefined.
      const allCompanies = [...userCompanies, ...filteredUniverseCompanies]
        .filter((company): company is CompanyWithName => typeof company.name === 'string')
        .sort((a, b) => a.name.localeCompare(b.name));

      // set list
      dispatch(setUserCompanies(allCompanies));

      return response.data.data;

    } catch (error) {

      const axiosError = error as AxiosError;

      // check if user still has access (we dont want this error to be propagated to sentry)
      if (axiosError.response && axiosError.response.status === 401 && !Router.pathname.includes('/auth')) {

        // Perform a client-side redirect to the sign-in page
        if (typeof window !== 'undefined') {
          window.location.href = '/auth/sign-out';
        }
        return rejectWithValue('Unauthorized - Redirecting to sign-in');
      } else {

        // register error in sentry
        new AppError(error, {
          where: 'fetchUser userSlice',
        });
      }

      return rejectWithValue(axiosError.message);
    }
  }
);

export const patchUser = createAsyncThunk(
  'user/patchUser',
  async (payload: BaseExchange<Partial<MeEntity>>) => {

    try {
      const response: BaseResponse<MeEntity> = await patch(payload);
      return response.data.data;
    } catch (error) {

      // register error in sentry
      throw new AppError(error, {
        where: 'patchUser userSlice',
        payload
      });
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUserState: () => initialState,
    setUserCompanies(state, action) {
      state.companies = [...action.payload];
    },
    setHasSsn(state, action) {
      state.hasSsn = action.payload;
    },
    setHasPhone(state, action) {
      state.hasPhone = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        state.status = 'fetching';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {

        if (action.payload) {
          state.data = { ...action.payload };
          state.hasSsn = state.data?.socialSecurityNumber ? validateSsn(state.data?.socialSecurityNumber) : false;
          state.hasPhone = !!state.data?.phone;
          state.currentCompany = state.data.companies.find(c => c.default);
        }

        state.loading = false;
        state.status = 'ready';
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.status = 'error';
        state.error = action.error.message;
      })
      .addCase(patchUser.pending, (state) => {
        state.loading = true;
        state.error = undefined;
        state.status = 'patching';
      })
      .addCase(patchUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = { ...action.payload };
          state.hasPhone = !!state.data?.phone;
          state.hasSsn = !!state.data?.socialSecurityNumber;
        }
        state.loading = false;
        state.status = 'ready';
      })
      .addCase(patchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  }
});

export const { resetUserState, setUserCompanies, setHasPhone, setHasSsn } = userSlice.actions;
export default userSlice.reducer;
