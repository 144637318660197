import { BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export interface Response {
  expiresAt: string;
  tokenType: string;
  token: string;
}

export async function get(uuid: string): Promise<BaseResponse<Response>> {
  return await API.get(`internal/v1/impersonate-sessions/${uuid}`)
}
