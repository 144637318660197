import { RefObject, useEffect, useRef } from 'react'

type HandlerType = () => void

interface ReturnType<T> {
  ref: RefObject<T> | null
}

export function useOnClickOutside<T extends HTMLElement>(handler: HandlerType): ReturnType<T> {

  const ref = useRef<T | null>(null);


  function handleClickOutside(event: MouseEvent) {
    if (ref && ref.current && !ref.current.contains(event.target as Node)) {
      handler()
    }
  }

  useEffect(() => {

    if (typeof window !== 'undefined') ref.current = null;
    else {

      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      }
    }

  }, [])

  return {
    ref
  }
}
