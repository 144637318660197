export * as InvoiceDiscounting from './invoice-discounting'
export * as BusinessLoan from './business-loan'
export * as Factoring from './factoring'

import { PaginatedBaseResponse, Money, BaseExchange, EmptyBaseResponse } from '~/sdk/shared'
import { AxiosResponse } from 'axios'
import API from '~/sdk/client'
import * as SDK from '~/sdk'


export enum Type {
  NotSelected = 'NOT_SELECTED',
  Customized = 'CUSTOMIZED',
  InvoiceDiscounting = 'INVOICE_DISCOUNTING',
  Factoring = 'FACTORING',
  BusinessLoan = 'BUSINESS_LOAN',
  SellInvoiceContract = 'SELL_INVOICE_CONTRACT',
  SelectCustomer = 'SELECT_CUSTOMER',
  Escrow = 'ESCROW',
  Quickloan = 'QUICKLOAN',
}

export enum Status {
  requestSigning = 'REQUESTED_SIGNING',
  requestSigned = 'REQUESTED_SIGNED',
  approved = 'APPROVED',
  rejected = 'REJECTED',
}

export interface ListRequest {
  page?: number,
  size?: number
}

export interface Entity {
  id: number,
  status: Status,
  amount: Money,
  createdAt: string,
  type: Type,
  paidOutAmount: Money
}

export interface DeleteRequest {
  advancementId: string,
  target: SDK.Internal.V1.Auth.BankId.TargetUnit
}

export interface DeletePayload {
  target: SDK.Internal.V1.Auth.BankId.TargetUnit
}

export async function list(req?: ListRequest): Promise<AxiosResponse<PaginatedBaseResponse<Entity>>> {
  const query = `size=${req?.size || 25}&page=${req?.page || 1}`
  return await API.get(`internal/v1/company/applications?${query}`)
}

export async function remove(req: DeleteRequest): Promise<EmptyBaseResponse> {
  const payload: BaseExchange<DeletePayload> = {
    data: {
      target: req.target
    }
  }

  return await API.delete<BaseExchange<DeletePayload>, EmptyBaseResponse>(`internal/v1/company/applications/${req.advancementId}`, { data: payload })
}
